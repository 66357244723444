<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <s-toolbar
            :color="'#244093'"
            dark
            label="Reporte de Antiguamiento"
            class="tran"
          ></s-toolbar>
          <v-row style="margin: auto">
            <v-card width="100%">
              <v-row style="margin: auto">
                <v-col cols="4" lg="4" md="4">
                  <s-select-definition
                    :def="1140"
                    label="Estado"
                    v-model="objState"
                    return-object
                  ></s-select-definition>
                </v-col>
                <v-col sm="6" cols="6" lg="4" md="4">
                  <s-select-definition
                    :def="1215"
                    v-model="objMounth"
                    return-object
                    label="Seleccione periodo"
                  ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="2" md="2" class="mt-3">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'info'"
                    small
                    @click="run()"
                    >Buscar</v-btn
                  >
                </v-col>
                <v-col cols="4" lg="2" md="2" class="mt-3">
                  <v-btn
                    width="100%"
                    rounded
                    :color="'error'"
                    small
                    @click="downloadReport()"
                    :disabled="stateBtnDownload"
                    >Descargar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-tabs v-model="currentItem">
        <v-tab href="#Dashboard"> Dashboard</v-tab>
        <v-tab href="#ReportCustomer"> Reporte de Clientes</v-tab>
        <v-tab href="#ByProject"> Por Proyecto</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentItem">
        <v-tab-item :value="'Dashboard'">
          <clc-report-antiquity-dashboard
            :items="dataDashboard"
            :objDrilldown="objDrilldown"
          ></clc-report-antiquity-dashboard>
        </v-tab-item>
        <v-tab-item :value="'ReportCustomer'">
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cuadrante, lote, Dni"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <gral-table
                  :headers="headers"
                  :items="items"
                  :propsdinamic="{
                    class: 'fixed-second-column',
                    search: this.search,
                  }"
                  :columnsNotNumber="['CodigoVenta', 'Documento', 'AnioVenta']"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
        <v-tab-item :value="'ByProject'">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <gral-table
                  :headers="headersByProject"
                  :items="itemsByProject"
                  :propsdinamic="{ class: 'fixed-first-column' }"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog
        v-model="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar busqueda <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcReportAntiquityDashboard from "./ClcReportAntiquityDashboard.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    GralTable,
    ClcReportAntiquityDashboard,
  },
  data() {
    return {
      search: "",
      currentItem: "tab-Funciones",
      items: [],
      headers: [],
      report: {},
      processing: false,
      messageProcessing: "",
      objState: null,
      dataDashboard: [],
      objDrilldown: [],
      group: [],
      temp: [],
      itemsByProject: [],
      headersByProject: [],
      stateBtnDownload: true,
      objMounth: null,
    };
  },
  methods: {
    run() {
      this.runView();
      this.runByProject();
      this.stateBtnDownload = false;
    },

    runView() {
      var report = {};
      this.messageProcessing = "";
      report.QryParamsArray = "StateName,Period";
      report.QryParamsArrayValue =
        this.objState.DedDescription + "," + this.objMounth.DedAbbreviation; //this.DateBegin + "," + this.DateEnd + "," + this.number;
      report.QryNameProcedure = "ClcReportAntiquaty_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.items = resp.data;

              this.headers = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
                filterable:
                  item === "CodigoVenta" || item === "Lote" ? true : false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    runByProject() {
      this.messageProcessing = "";
      var report = {};
      report.QryParamsArray = "StateName,Period";
      report.QryParamsArrayValue =
        this.objState.DedDescription + "," + this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportAntiquaty_Resumen";
      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.dataDashboard = resp.data
                .filter((item) => item.Cuadrante !== "Total General")
                .map((item) => ({
                  name: item.Cuadrante,
                  y: item["TotalGeneral"],
                  drilldown: item.Cuadrante,
                }));
              this.objDrilldown = resp.data.map((item) => ({
                id: item.Cuadrante,
                name: item.Cuadrante,
                data: Object.entries(item).filter((obj) =>
                  obj[0].includes("[")
                ),
              }));
              this.itemsByProject = resp.data;
              this.headersByProject = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    downloadReport() {
      const SheetNames = ["Por Cod Venta", "Por Proyecto"];
      const DataTables = [this.items, this.itemsByProject];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                `Antiquamiento ${this.objState.DedDescription} ${this.objMounth.DedDescription}`
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}

.fixed-first-column thead tr,
.fixed-first-column tbody tr,
.fixed-second-column thead tr,
.fixed-second-column tbody tr {
  position: relative;
}
.fixed-first-column thead th:first-child,
.fixed-first-column tbody td:first-child,
.fixed-second-column thead th:nth-child(2),
.fixed-second-column tbody td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white; /* Ajusta el fondo según tus necesidades */
  z-index: 1;
}
</style>
